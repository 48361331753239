<template>
  <div class="page">
    <div class="tip">
      <p>
        说明：会员导入适用于会员迁移得场景，系统会导入增量会员并更新未激活会员信息，不得更新已激活会员信息。
      </p>
      <div>
        <a-button type="primary" style="margin-right: 10px;"> 设置激活通知 </a-button>
        <a-button type="primary"> 会员导入 </a-button>
      </div>
    </div>
    <div class="search__box">
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="inline"
      >
        <a-row>
          <a-col span="6">
            <a-form-model-item label="导入批次号">
              <a-input class="w220" v-model="form.name" />
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="导入时间">
              <a-range-picker :placeholder="['开始时间', '结束时间']">
              </a-range-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="2">
            <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
              <a-button type="primary" @click="onSubmit"> 筛选 </a-button>
            </a-form-model-item>
          </a-col>
          <a-col span="2">
            <a-form-model-item :wrapper-col="{ span: 20, offset: 0 }">
              <a-button type="primary" @click="onSubmit"> 查看未激活会员 </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <div class="action_custom">
          <a-icon type="delete" @click="deleteHandle(record)" />
          <a-icon type="close-circle" />
          <a-icon type="arrow-down" />
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "导入批次号",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "导入时间",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "会员卡",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "成功数量",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "失败数量",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "激活数量",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
export default {
  name: "MemberImport",
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      data,
      columns,
      pagination: {
        total: 100,
        showTotal: (total, range) => {
          console.log(total, range);
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.search__box {
  margin-bottom: 10px;
}
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.tip {
  padding: 20px;
  background: #ddd;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
  margin-bottom: 10px;
}
</style>


